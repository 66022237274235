.filter-tags-container .syt-antd-tree-checkbox-inner {
  transform: scale(1.1);
  border-color: #c2c7d0;
  outline: none;
}

.filter-tags-container .syt-antd-tree-treenode {
  margin-bottom: 4px;
}

.filter-tags-container {
  margin-left: -24px;
}

.tasks-tag-filter-radio-group span:first-child {
  margin-bottom: 16px;
}
